import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import Utils from "utils";
import { env } from "./EnvironmentConfig";

export const APP_NAME =
  process.env.REACT_APP_SOURCE === "NAVER"
    ? "NAVER Cloud Corp."
    : "NBASE Corp.";
export const API_BASE_URL = env.API_ENDPOINT_URL;
// export const APP_PREFIX_PATH = "/app";
// export const APP_PREFIX_PATH = `/${localStorage.getItem(
//   "companyId"
// )}/${localStorage.getItem("projectId")}`;
// export const APP_PREFIX_PATH = `/${localStorage.getItem(
//   "companyId"
// )}/${Utils.getProjectId()}`;
export const APP_PREFIX_PATH = `/:companyId/:projectId`;
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = "/login";

// localStorage에서 navCollapsed 상태 읽기
const getSavedNavCollapsed = () => {
  try {
    const savedNavCollapsed = localStorage.getItem("navCollapsed");
    return savedNavCollapsed ? JSON.parse(savedNavCollapsed) : false;
  } catch (error) {
    return false;
  }
};

export const THEME_CONFIG = {
  navCollapsed: getSavedNavCollapsed(),
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: localStorage.getItem("locale") || "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: localStorage.getItem("currentTheme") || "light",
  direction: DIR_LTR,
  blankLayout: false,
};
