import React from "react";
import {
  Navigate,
  Outlet,
  // useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  // REDIRECT_URL_KEY,
} from "configs/AppConfig";
import Utils from "utils";
import ErrorOne from "views/auth-views/errors/error-page-1";

const checkAccess = (menuItems, pathname) => {
  return menuItems.some(menu => {
    // 메뉴 경로가 현재 경로에 포함되어 있는지 확인
    if (pathname.includes(menu.path)) return true;
    
    // 하위 메뉴가 있으면 재귀적으로 체크
    if (menu.submenu && menu.submenu.length > 0) {
      return checkAccess(menu.submenu, pathname);
    }
    
    return false;
  });
};

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const menuData = useSelector((state) => state.auth.menu); 
  const location = useLocation();
  const companyId = localStorage.getItem("companyId");
  const projectId = Utils.getProjectId();
  
  if (!token) {
    return (
      <Navigate
        // to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        to={`${
          companyId ? `/${companyId}` : ""
        }${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace
      />
    );
  }
  
  // 메뉴에 없는 경우 error 페이지로 이동
  const hasAccess = checkAccess(menuData, location.pathname);
  if (!hasAccess && !location.pathname.endsWith('/dashboards/default')) {
    return (
      // <Route path="/error" element={<ErrorOne />} />
      <Navigate
        to={`${companyId ? `/${companyId}` : ""}${projectId ? `/${projectId}` : ""}/error`}
        element={<ErrorOne />}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
