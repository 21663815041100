import React from "react";
import {
  Navigate,
  Outlet,
  // useLocation
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  // REDIRECT_URL_KEY,
} from "configs/AppConfig";
import Utils from "utils";
import { signOut } from "store/slices/authSlice";

const checkAccess = (menuItems, pathname) => {
  // 경로에서 마지막에 UUID 또는 ID가 있는지 확인하기 위한 정규식
  const uuidRegex = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  const idRegex = /\/\d+$/;
  
  // UUID 또는 ID를 제거한 경로
  let cleanedPath = pathname;
  if (uuidRegex.test(pathname)) {
    cleanedPath = pathname.replace(uuidRegex, '');
  } else if (idRegex.test(pathname)) {
    cleanedPath = pathname.replace(idRegex, '');
  }
  
  return menuItems.some(menu => {
    // 기본 경로 일치 확인
    if (pathname === menu.path || cleanedPath === menu.path) return true;
    
    // 와일드카드 처리 (예: /path/* 형태의 메뉴 항목)
    if (menu.path.endsWith('/*') && pathname.startsWith(menu.path.slice(0, -2))) return true;
    
    // 경로 시작 부분이 일치하는지 확인 (ID나 UUID가 붙은 상세 페이지)
    if (pathname.startsWith(menu.path + '/')) return true;
    
    // 하위 메뉴가 있으면 재귀적으로 체크
    if (menu.submenu && menu.submenu.length > 0) {
      return checkAccess(menu.submenu, pathname);
    }
    
    return false;
  });
};

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const menuData = useSelector((state) => state.auth.menu); 
  const location = useLocation();
  const companyId = localStorage.getItem("companyId");
  const projectId = Utils.getProjectId();
  const dispatch = useDispatch();
  
  // console.log("ProtectedRoute check:", { pathname: location.pathname, menuDataLength: menuData?.length });
  
  if (!token) {
    return (
      <Navigate
        // to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        to={`${
          companyId ? `/${companyId}` : ""
        }${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace
      />
    );
  }
  
  // 에러 페이지 경로 패턴 분석
  const pathParts = location.pathname.split('/').filter(Boolean);
  
  // /error 또는 /:companyId/error 패턴인 경우 로그아웃 처리 후 로그인 페이지로 이동
  if ((pathParts.length === 1 && pathParts[0] === 'error') || 
      (pathParts.length === 2 && pathParts[1] === 'error')) {
    // console.log("Signing out and redirecting to login page from error path:", location.pathname);
    dispatch(signOut());
    return (
      <Navigate
        to={`${
          companyId ? `/${companyId}` : ""
        }${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace
      />
    );
  }
  
  // /:companyId/:projectId/error 패턴인 경우 에러 페이지 표시
  if (pathParts.length === 3 && pathParts[2] === 'error') {
    // console.log("Showing error page:", location.pathname);
    return <Outlet />;
  }
  
  // 대시보드 경로인 경우는 항상 허용 (기본 리다이렉트 경로이므로)
  if (location.pathname.includes('/dashboards/')) {
    // console.log("대시보드 경로 접근 허용:", location.pathname);
    return <Outlet />;
  }
  
  // 메뉴 데이터가 아직 로드되지 않은 경우 잠시 대기 (메뉴 데이터 로딩이 필요한 경우에만)
  if (!menuData || menuData.length === 0) {
    // console.log("메뉴 데이터가 아직 로드되지 않음 - 접근 일시 허용");
    return <Outlet />;
  }
  
  // 메뉴에 없는 경우 error 페이지로 이동
  const hasAccess = checkAccess(menuData, location.pathname);
  // console.log("메뉴 접근 권한 체크:", { pathname: location.pathname, hasAccess });
  
  if (!hasAccess) {
    // console.log("메뉴 접근 권한 없음, 에러 페이지로 이동:", location.pathname);
    return (
      <Navigate
        to={`${companyId ? `/${companyId}` : ""}${projectId ? `/${projectId}` : ""}/error`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
