import { gql } from "@apollo/client";

const PROJECTS_QUERY = gql`
  query PROJECTS_QUERY(
    $ncpId: String!
    $offset: Int
    $per_page: Int
    $query: String
    $search: String
  ) {
    projects(
      ncpId: $ncpId
      offset: $offset
      per_page: $per_page
      query: $query
      search: $search
    ) {
      totalCount
      edges {
        node {
          id
          category_id
          paid
          push
          name
          desc
          hash_key
          fcm
          license
          public_key {
            google
            apple
            one
          }
          app_id {
            google
            apple
            one
            galaxy
          }
          server_key {
            google
            apple
            one
          }
          auth_key {
            google_client_id
            facebook_app_id
            facebook_app_secret
            apple_key
            apple_key_id
            apple_team_id
            apple_bundle_id
          }
          webhook {
            purchase
            purchase_sandbox
            item
            item_sandbox
            purchase_block
            item_block
          }
          cdn
          ncp {
            objectstorage_access_key
            objectstorage_secret_key
            objectstorage_bucket
            sens_sms_service_id
            sens_sms_access_key
            sens_push_service_id
            sens_push_secret_key
            sens_push_access_key
          }
          language_list
          third_pay_desc
          ncp_project_id
          third_sdk
          plugin {
            api_key
            url
            member_migration
          }

          clover {
            use
            projectid
            apikey
            clientkey
            superuserkey
          }

          papago {
            use
            client_id
            client_secret
          }
          objectstorage
          cloudchat {
              enabled
            project_id
            __typename
          }
          cloverx
          sentiment
          sens
          translate
          createdAt
          base_currency
        }
      }
    }
  }
`;

const PROJECT_QUERY = gql`
  query PROJECT_QUERY($id: ID!) {
    project(id: $id) {
      id
      category_id
      push
      name
      desc
      key
      hash_key
      fcm
      paid
      google_iap_valid_version
      apple_allow_bundle_ids
      apple_app_shared_secret
      public_key {
        google
        googleplay
        googleplay_package
        apple
        one
        oneplay
        oneplay_package
      }
      app_id {
        google
        google_package
        googleplay
        googleplay_package
        apple
        apple_package
        appleplay
        appleplay_package
        one
        one_package
        oneplay
        oneplay_package
        galaxy
        galaxy_package
        galaxyplay
        galaxyplay_package
      }
      google_key {
        google
        googleplay
      }
      apple_key {
        client_id
        team_id
        key_id
        scope
        private_key
      }
      server_key {
        google
        apple
        one
        oneplay
      }
      plugin {
        api_key
        url
        member_migration
      }
      auth_key {
        enable
        google_client_id
        facebook_app_id
        facebook_app_secret
        apple_key
        apple_key_id
        apple_team_id
        apple_bundle_id
      }
      webhook {
        purchase
        purchase_sandbox
        item
        item_sandbox
        memberblock
        purchase_block
        item_block
      }
      language_list
      outbound_send_mail_address
      outbound_send_mail_name
      outbound_send_mail_header
      outbound_send_mail_title
      outbound_send_mail_replytitle
      outbound_send_mail_questiontitle
      outbound_send_mail_footer
      cs_mail_enable
      cs_show_input_phone
      cs_show_input_email
      cs_mail_enable
      cs_show_input_phone
      cs_show_input_email
      cdn
      ncp {
        objectstorage_access_key
        objectstorage_secret_key
        objectstorage_bucket
        sens_sms_service_id
        sens_sms_access_key
        sens_push_service_id
        sens_push_secret_key
        sens_push_access_key
      }
      language_list
      plugin {
        api_key
        url
        member_migration
      }
      third_pay_desc
      ncp_project_id
      third_sdk
      clover {
        use
        projectid
        apikey
        clientkey
        superuserkey
      }

      papago {
        use
        client_id
        client_secret
      }
      extra_permission
      base_currency
    }
  }
`;

const PROJECT_LANGUAGE_QUERY = gql`
  query PROJECT_LANGUAGE_QUERY($id: ID!) {
    project(id: $id) {
      id
      language_list
    }
  }
`;

const PROJECT_VOIDED_QUERY = gql`
  query PROJECT_VOIDED_QUERY($id: ID!) {
    project(id: $id) {
      id
      voided_enable
      voided_count
      voided_price
      voided_message
      voided_messageMulti {
        default
        lang
        value
      }
      voided_updatedAt
      google_key {
        google
        googleplay
      }
      language_list
    }
  }
`;

const PROJECT_WITHDRAW_QUERY = gql`
  query PROJECT_WITHDRAW_QUERY($id: ID!) {
    project(id: $id) {
      id
      withdraw_hours
      withdraw_enable
    }
  }
`;

const PROJECT_CS_DECO_QUERY = gql`
  query PROJECT_CS_DECO_QUERY($id: ID!) {
    project(id: $id) {
      id
      baseUrl
      cs_theme
      cs_header_enable
      cs_header {
        lang
        value
        default
      }
      cs_footer_enable
      cs_footer {
        lang
        value
        default
      }
      cs_logo_header {
        lang
        value
        default
      }
      cs_logo_footer {
        lang
        value
        default
      }
    }
  }
`;

const PROJECT_GDPR_QUERY = gql`
  query PROJECT_GDPR_QUERY($id: ID!) {
    project(id: $id) {
      id
      gdpr {
        enable
        isAdApp
        sender_email
        sender_name
      }
    }
  }
`;

export {
  PROJECTS_QUERY,
  PROJECT_GDPR_QUERY,
  PROJECT_LANGUAGE_QUERY,
  PROJECT_QUERY,
  PROJECT_VOIDED_QUERY,
  PROJECT_CS_DECO_QUERY,
  PROJECT_WITHDRAW_QUERY
};
