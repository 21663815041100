import React, { useEffect } from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
// import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "store/slices/authSlice";
import { useNavigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import Utils from "utils";
import ErrorBoundary from "ErrorBoundary";
import ErrorOne from "views/auth-views/errors/error-page-1";
import ErrorThree from "views/auth-views/errors/error-page-3";

const Routes = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 현재 경로를 localStorage에 저장
  useEffect(() => {
    if (location && location.pathname) {
      localStorage.setItem('current_path', location.pathname);
    }
  }, [location]);

  // 새로고침 시 메뉴 상태 복원
  useEffect(() => {
    // 페이지 로드 시 한 번만 실행되는 코드
    const handlePageLoad = () => {
      const savedPath = localStorage.getItem('current_path');
      if (savedPath && location.pathname !== savedPath) {
        // 저장된 경로가 있지만 현재 경로와 다른 경우 (새로고침 후)
        // 우측 페이지는 유지되므로 추가 이동은 필요없음
        console.log('Restoring menu state for path:', savedPath);
      }
    };

    handlePageLoad();
  }, []);

  React.useEffect(() => {
    if (token) {
      const path = location.pathname.split("/");
      switch (path.length) {
        case 2:
          if (path[1] !== "error") {
            dispatch(signOut());
            // navigate("/auth/login");
            navigate(`/${localStorage.getItem("companyId")}/auth/login`);
          }
          break;
        case 3:
          if (path[2] !== "projects") {
            navigate(`/${path[1]}/${path[2]}/dashboards/default`);
          }
          break;
        default:
          break;
      }
    }
  }, [location]);

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        {/* <Route
          path="/" */}
        {/* element={<Navigate replace to={AUTHENTICATED_ENTRY} />} */}
        {/* element={
            <Navigate
              replace
              to={`/${Utils.getCompanyId()}/${Utils.getProjectId()}/dashboards/default`}
            />
          }
        /> */}
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                // <ErrorBoundary>
                //   <AppRoute
                //     routeKey={route.key}
                //     component={route.component}
                //     {...route.meta}
                //   />
                // </ErrorBoundary>
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="/error" element={<ErrorOne />} />
        <Route path="/:companyId/:projectId/error" element={<ErrorThree />} />
        <Route path="/:companyId/error" element={<ErrorThree />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
