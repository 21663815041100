import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import momentTimezone from "moment-timezone";

import Utils from "utils";

// Assuming the URL is http://example.com/firstParam/secondParam
const currentUrl = window.location.href; // gets the full URL
const url = new URL(currentUrl);
const pathSegments = url.pathname.split("/"); // splits the path by "/"

// Getting the first parameter from the path
// The first element in the array is an empty string before the first "/", so the second element is the first parameter
const firstParam = pathSegments[1];

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // token: localStorage.getItem(AUTH_TOKEN) || null,
  token:
    localStorage.getItem("companyId") === firstParam
      ? localStorage.getItem(AUTH_TOKEN)
      : null,
  menu: [],
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    const { email, password, company_id } = data;
    localStorage.setItem("companyId", company_id);
    const response = await AuthService.login({
      username: email,
      password,
      ncpid: company_id,
    });
    if (response.status) {
      const token = response.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return { token: token, response: response };
    } else {
      localStorage.removeItem("companyId");
      return rejectWithValue(response.error || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password, company_id, name } = data;
    localStorage.setItem("companyId", company_id);
    try {
      const response = await AuthService.register({
        username: email,
        ncp_project_id: company_id,
        password,
        name,
        timezone: momentTimezone.tz.guess(),
      });
      if (response.user) {
        // Sign-in logic after successful sign-up
        const signInResponse = await AuthService.login({
          username: email,
          password,
          ncpid: company_id,
        });

        if (signInResponse.status) {
          const token = signInResponse.token;
          localStorage.setItem(AUTH_TOKEN, token);
          window.location.href = `/${company_id}/projects`;
          return { token: token, response: signInResponse };
        } else {
          localStorage.removeItem("companyId");
          return rejectWithValue(
            signInResponse.error || "Error during sign-in"
          );
        }
      } else {
        localStorage.removeItem("companyId");
        return rejectWithValue(response.error || "Error during sign-up");
      }
    } catch (err) {
      localStorage.removeItem("companyId");
      return rejectWithValue(Utils.getErrorMessages(err));
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await AuthService.logout();
  localStorage.removeItem(AUTH_TOKEN);
  // 메뉴 상태 초기화 - 로그아웃 시 메뉴 상태 제거
  localStorage.removeItem('menu_open_keys');
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      console.log("authenticated");
      state.loading = false;
      // state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = `/${localStorage.getItem("companyId")}`;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      console.log("signInSuccess");
      state.loading = false;
      state.token = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        console.log(
          `${action.payload.response.ncpid}/${action.payload.response.project_id}/dashboards/default`
        );
        state.loading = false;
        // state.redirect = `${action.payload.response.ncpid}/${action.payload.response.project_id}/dashboards/default`;
        state.token = action.payload.token;
        // 메뉴 상태 초기화 - 로그인 시 메뉴가 접힌 상태로 시작하도록 함
        localStorage.removeItem('menu_open_keys');
        localStorage.setItem('menu_open_keys', JSON.stringify([]));
        window.location.href = `/${action.payload.response.ncpid}/${action.payload.response.project_id}/dashboards/default`;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        console.log(action);
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
        // 메뉴 상태 초기화 - 로그아웃 성공 시 메뉴 상태 제거
        localStorage.removeItem('menu_open_keys');
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
        // 메뉴 상태 초기화 - 로그아웃 실패 시에도 메뉴 상태 제거
        localStorage.removeItem('menu_open_keys');
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        // state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        console.log(action);
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

const authPersistConfig = {
  key: "auth", // key for localStorage key, you might want to namespace it like 'myAppAuth'
  storage,
  whitelist: ["menu"], // You can add other state parts here if needed
};

export const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice.reducer
);

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setMenu,
} = authSlice.actions;

// export default authSlice.reducer;
export default persistedAuthReducer;
