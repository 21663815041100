import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: false,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    fetchUserInfo: (state) => {
      // 이 액션은 미들웨어에서 처리됩니다.
      // 메뉴 상태를 초기화하기 위한 신호로 사용됩니다.
    },
  },
});

export const { setUser, fetchUserInfo } = userSlice.actions;

export default userSlice.reducer;
