import React from "react";
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import IntlMessage from "components/util-components/IntlMessage";

const ErrorThree = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const navigate = useNavigate();

  return (
    <div className={theme === "light" ? "bg-white" : ""}>
      <div className="container">
        <Row align="middle" style={{ minHeight: '70vh' }}>
          <Col xs={24} sm={24} md={8}>
            <h1 className="font-weight-bold mb-4 display-4">
              <IntlMessage id="Page not found" />
            </h1>
            <p className="font-size-md mb-4">
              <IntlMessage id="We've noticed you lost your way, no worries, we will help you to found the correct path." />
            </p>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined className="mr-1" />}
              onClick={() => navigate(-1)}
            >
              <IntlMessage id="Go back" />
            </Button>
          </Col>
          <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
            <img
              className="img-fluid mt-md-0 mt-4"
              src="/img/others/img-20.png"
              alt="404 Not Found Image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/img/others/img-20.png";
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ErrorThree; 