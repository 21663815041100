import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

// fetchUserInfo 액션이 디스패치될 때 메뉴 상태를 초기화하는 미들웨어
const apolloMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  
  if (action.type === 'userSlice/fetchUserInfo') {
    // 메뉴 상태 초기화
    store.dispatch({ type: 'auth/setMenu', payload: [] });
    
    // localStorage에서 메뉴 상태 초기화
    localStorage.removeItem('menu_open_keys');
    localStorage.setItem('menu_open_keys', JSON.stringify([]));
  }
  
  return result;
};

const middlewares = [apolloMiddleware];

const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

const persistor = persistStore(store);
store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(rootReducer(store.asyncReducers));
  return store;
};

export default store;
export { persistor };
