import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from "@tanstack/react-query";
import { message } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import { globalMessageHandler } from "utils/globalMessageHandler";

import store, { persistor } from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { signOut } from "store/slices/authSlice";
import { AUTH_TOKEN } from "constants/AuthConstant";
import Utils from "utils";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// link to graphql
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

// authorization middleware
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    const locale = navigator.language;

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
        ncpid: Utils.getCompanyId(),
        "accept-language": locale,
        language: locale,
      },
    };
  });

  return forward(operation);
});

// error handling
// const logoutLink = onError(({ networkError }) => {
//   if (networkError?.statusCode !== 200) {
//     signOut();
//   }
// });
const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // );
      globalMessageHandler.error(message);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    globalMessageHandler.error("Network error, please try again.");
    // Logout on unauthorized error
    if (networkError.statusCode === 401 || networkError.statusCode === 403) {
      store.dispatch(signOut()); // Ensure you have access to your Redux store to dispatch
    }
  }
});

const client = new ApolloClient({
  link: authMiddleware.concat(logoutLink).concat(httpLink),
  cache: new InMemoryCache({ resultCaching: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
});

// 앱 초기화 시 메뉴 상태 초기화 함수
const initializeAppState = () => {
  // 페이지 새로고침 감지
  const isPageRefresh = performance.navigation && 
                        performance.navigation.type === 1;
  
  if (isPageRefresh) {
    // 메뉴 상태는 SideNav, MenuContent 컴포넌트에서 복원됩니다.
    // 여기서는 필요한 초기화 작업만 수행
  }
};

// 앱 초기화
initializeAppState();

function App() {
  const [messagePopup, messageHolder] = message.useMessage();
  // global error handling
  const mutationCache = new MutationCache({
    onError(error) {
      messagePopup.error(
        `Something went wrong: ${Utils.getErrorMessages(error)}`
      );
    },
  });

  // global error handling
  const queryCache = new QueryCache({
    onError(error) {
      messagePopup.error(
        `Something went wrong: ${Utils.getErrorMessages(error)}`
      );
    },
  });

  const tancentClient = new QueryClient({
    mutationCache,
    queryCache,
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={tancentClient}>
        <div className="App">
          {messageHolder}
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter history={history}>
                <ThemeSwitcherProvider
                  themeMap={themes}
                  defaultTheme={THEME_CONFIG.currentTheme}
                  insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </div>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
export { client };
